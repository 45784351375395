import React from 'react';
import { getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import UIkitPicture, { jpegBackupSource, ImageFormat, PictureProps } from '@westwing/ui-kit/Picture';
import styled, { css } from 'styled-components';
import { useImageTagFormatter } from '../../hooks/imageFormatting';
import { imageLoadErrorFunction } from './GenericProductImage';
import { useLogger } from 'AppShell/logger/LoggerProvider';
import { ImageSizeInfo } from '@westwing/ui-kit/utils/image/types';
import { IMAGE_WIDTHS } from '@westwing/ui-kit/utils/image';
import { ProductImageTag, ProductImageTagType } from '@westwing/ui-kit/ProductTile';
import { ObjectFit } from '@westwing/ui-kit/Picture/Image';

const ImageStyle = css`
    width: 100%;
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
`;

const StyledUIkitPicture = styled(UIkitPicture)`
    ${ImageStyle}
`;

interface ImageSizeAndWidth extends ImageSizeInfo {
    imageWidth: number;
}

const IMAGE_SIZES: { [key: string]: ImageSizeAndWidth } = {
    CART: {
        size: {
            width: 160,
            height: 182,
        },
        imageWidth: IMAGE_WIDTHS.CART_IMAGE,
    },
    RATINGS: {
        size: {
            width: 248,
            height: 205,
        },
        imageWidth: IMAGE_WIDTHS.RATINGS_OVERLAY_IMAGE,
    },
};

export type ImagesTypes = keyof typeof IMAGE_SIZES;
const getImageType = (imageType: ImagesTypes) => IMAGE_SIZES[imageType];

export interface ProductImageProps extends Partial<PictureProps> {
    src: string;
    alt: string;
    imageType: ImagesTypes;
    imageTag?: ProductImageTagType;
    imagePadding?: boolean;
    isClubItem?: boolean;
}

export const ProductImage: React.FC<ProductImageProps> = ({
    className,
    src,
    alt = '',
    imageType,
    imageTag = ProductImageTag.REGULAR,
    imagePadding = true,
    isClubItem,
    ...restUiKitPictureProps
}) => {
    const formatter = useImageTagFormatter(imageTag, { padding: imagePadding, isClubItem, isTrimmed: false });
    const logger = useLogger();

    const imageSize: ImageSizeAndWidth = getImageType(imageType);

    const { webpSrc, jpegSrc } = formatter({
        src,
        imageWidth: imageSize.imageWidth,
        imageSizeInfo: imageSize,
        isClubItem,
    });

    const sources = [
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop', true),
                url: webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop', true),
                url: webpSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop'),
                url: webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop'),
                url: webpSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
    ];

    return (
        <StyledUIkitPicture
            {...restUiKitPictureProps}
            loading="eager"
            sources={sources}
            fallback={{
                url: jpegSrc,
                className,
                alt,
            }}
            errorFunction={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                imageLoadErrorFunction(e, logger, String(imageType), { url: src })
            }
            objectFit={isClubItem ? ObjectFit.CONTAIN : ObjectFit.COVER}
        />
    );
};
