import React, { ReactElement, SyntheticEvent } from 'react';
import { FormattedImageData, FormattedMoodImageData, ProductInterface } from '../../redux/products/types';
import useRouter from 'AppShell/hooks/useRouter';
import PLPImage from './PLPImage';
import { Logger } from 'AppShell/appshell_types/loggerTypes';
import { useLogger } from 'AppShell/logger/LoggerProvider';

export interface GenericProductImageProps {
    product: ProductInterface;
    imageData: FormattedImageData | FormattedMoodImageData;
    isHeroProduct?: boolean;
    loading?: 'lazy' | 'eager';
}

// eslint-disable-next-line max-params
export const logImageErrorWarning = (url: string, tag: string, logger: Logger, customData?: Object) => {
    logger.warn(`[${tag}] Image with src - "${url}" failed to load`, customData);
};

export const imageLoadErrorFunction = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    logger: Logger,
    tag: string,
    customData?: Object
    // eslint-disable-next-line max-params
) => {
    logImageErrorWarning(e.currentTarget.currentSrc, tag, logger, customData);
};

const GenericProductImage: React.FC<GenericProductImageProps> = React.memo(
    ({ product, isHeroProduct, imageData, loading = 'lazy' }): ReactElement | null => {
        const { altTag, simpleSku, name } = product;
        const logger = useLogger();

        const {
            host,
            location: { pathname, search },
        } = useRouter();

        const customData = {
            url: `${host}${pathname}${search}`,
            product: name,
            simpleSku,
        };

        return (
            <PLPImage
                imageTag={imageData.tag}
                seoSrc={imageData.seoUrl}
                loading={loading}
                src={product.bestSellingImage?.path || imageData.path}
                alt={altTag}
                isHeroProduct={!!isHeroProduct}
                errorFunction={(e: SyntheticEvent<HTMLImageElement, Event>) =>
                    imageLoadErrorFunction(e, logger, 'PLP', customData)
                }
            />
        );
    }
);

export default GenericProductImage;
