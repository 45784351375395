import React, { FC } from 'react';
import UIkitPicture, { jpegBackupSource, ImageFormat } from '@westwing/ui-kit/Picture';
import { getMediaStringValue, iphoneXMediaQuery } from '@westwing/ui-kit/breakPoints';
import styled from 'styled-components';
import { UsePdpSliderImagePropsConfig } from '../ProductDetailPage/Gallery/usePdpSliderImageProps';
import { useImageTagFormatter } from '../../hooks/imageFormatting';
import { getPLPImageSizeInfo, getPLPHeroImageSizeInfo } from './imageSizeConfigs';
import { IMAGE_WIDTHS } from '@westwing/ui-kit/utils/image';

export interface Props extends UsePdpSliderImagePropsConfig {
    isHeroProduct: boolean;
    loading: 'eager' | 'lazy';
}

const StyledUIkitPicture = styled(UIkitPicture)`
    width: 100%;
    height: fit-content;
`;

export const PLPImage: FC<Props> = ({
    className,
    src,
    alt = '',
    imageTag,
    loading = 'lazy',
    isHeroProduct,
    seoSrc,
    errorFunction,
}) => {
    const formatter = useImageTagFormatter(imageTag);

    if (!src) {
        return null;
    }

    const { webpSrc: iphoneXSrcWebp, jpegSrc: iphoneXSrcJpeg } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.PLP_IPHONE_X,
        imageSizeInfo: isHeroProduct
            ? getPLPHeroImageSizeInfo(IMAGE_WIDTHS.PLP_HERO_IPHONE_X, imageTag)
            : getPLPImageSizeInfo(IMAGE_WIDTHS.PLP_IPHONE_X),
    });

    const { webpSrc: mobileSrcWebp, jpegSrc: mobileSrcJpeg } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.PLP_MOBILE_LOW_DENSITY,
        imageSizeInfo: isHeroProduct
            ? getPLPHeroImageSizeInfo(IMAGE_WIDTHS.PLP_HERO_MOBILE_LOW_DENSITY, imageTag)
            : getPLPImageSizeInfo(IMAGE_WIDTHS.PLP_MOBILE_LOW_DENSITY),
    });
    const { webpSrc: mobileSrcWebp2xDpi, jpegSrc: mobileSrcJpeg2xDpi } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.PLP_MOBILE_HIGH_DENSITY,
        imageSizeInfo: isHeroProduct
            ? getPLPHeroImageSizeInfo(IMAGE_WIDTHS.PLP_HERO_MOBILE_HIGH_DENSITY, imageTag)
            : getPLPImageSizeInfo(IMAGE_WIDTHS.PLP_MOBILE_HIGH_DENSITY),
    });

    const { webpSrc: desktopTabletWebpSrc, jpegSrc: desktopTabletJpegSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.PLP_DESKTOP_TABLET_LOW_DENSITY,
        imageSizeInfo: getPLPImageSizeInfo(IMAGE_WIDTHS.PLP_DESKTOP_TABLET_LOW_DENSITY),
    });
    const { webpSrc: desktopTabletWebp2xDpiSrc, jpegSrc: desktopTabletJpeg2xDpiSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.PLP_DESKTOP_TABLET_HIGH_DENSITY,
        imageSizeInfo: getPLPImageSizeInfo(IMAGE_WIDTHS.PLP_DESKTOP_TABLET_HIGH_DENSITY),
    });

    const { webpSrc: desktopWideScreenWebpSrc, jpegSrc: desktopWideScreenJpegSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.PLP_DESKTOP_WIDE_SCREEN_LOW_DENSITY,
        imageSizeInfo: getPLPImageSizeInfo(IMAGE_WIDTHS.PLP_DESKTOP_WIDE_SCREEN_LOW_DENSITY),
    });
    const { webpSrc: desktopWideScreenWebp2xDpiSrc, jpegSrc: desktopWideScreenJpeg2xDpiSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.PLP_DESKTOP_WIDE_SCREEN_HIGH_DENSITY,

        imageSizeInfo: getPLPImageSizeInfo(IMAGE_WIDTHS.PLP_DESKTOP_WIDE_SCREEN_HIGH_DENSITY),
    });

    const tabletSource2xDpi = jpegBackupSource(
        {
            media: getMediaStringValue('breakpointTablet', true),
            url: mobileSrcWebp2xDpi,
            value: 2,
            format: ImageFormat.WEBP,
        },
        mobileSrcJpeg2xDpi
    );

    const sources = [
        ...jpegBackupSource(
            {
                media: iphoneXMediaQuery,
                url: iphoneXSrcWebp,
                value: 1,
                format: ImageFormat.WEBP,
            },
            iphoneXSrcJpeg
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet', true),
                url: mobileSrcWebp,
                value: 1,
                format: ImageFormat.WEBP,
            },
            mobileSrcJpeg
        ),

        ...tabletSource2xDpi,

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: desktopTabletWebpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            desktopTabletJpegSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: desktopTabletWebp2xDpiSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            desktopTabletJpeg2xDpiSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointSemiWideScreens'),
                url: desktopWideScreenWebpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            desktopWideScreenJpegSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointSemiWideScreens'),
                url: desktopWideScreenWebp2xDpiSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            desktopWideScreenJpeg2xDpiSrc
        ),
    ];

    // todo: remove once the api sends the correct seo_urls to all responses. P100-2962
    // t_default.plp_seo is a named transformation and can be configured in the image-service
    let seoUrl = seoSrc;
    if (!seoSrc.includes('/t_default.plp_seo')) {
        seoUrl = tabletSource2xDpi[0].url;
    }

    return (
        <StyledUIkitPicture
            sources={sources}
            loading={loading}
            /* See comments in "src/client/components/ProductDetailPage/Gallery/PDPSliderImage.tsx" */
            fallback={{
                url: seoUrl,
                className,
                alt,
            }}
            errorFunction={errorFunction}
        />
    );
};

export default PLPImage;
