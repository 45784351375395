import { ProductImageTag, ProductImageTagType } from '@westwing/ui-kit/ProductTile';
import { ImageSizeInfo } from '@westwing/ui-kit/utils/image/types';

export const getPLPImageSizeInfo = (screenWidth: number): ImageSizeInfo => {
    const screenHeight = Math.round((screenWidth * 4) / 3);
    const horizontalPadding = Math.round(screenWidth / 12.5);
    const verticalPadding = Math.round(screenHeight / 5.681818182);

    return {
        padding: {
            top: verticalPadding,
            right: horizontalPadding,
            bottom: verticalPadding,
            left: horizontalPadding,
        },
        size: {
            width: screenWidth,
            height: screenHeight,
        },
    };
};

export const getPLPHeroImageSizeInfo = (screenWidth: number, imageTag: ProductImageTagType): ImageSizeInfo => {
    const heightDivisor = imageTag === ProductImageTag.DETAIL ? 4 / 3 : 1.03380281690141;

    const paddingMultiplier = imageTag === ProductImageTag.DETAIL ? 0 : 1;
    const screenHeight = Math.round(screenWidth * heightDivisor);
    const horizontalPadding = Math.round(screenWidth / 12.5);
    const verticalPadding = Math.round(screenHeight / 7.3529411765);

    return {
        padding: {
            top: verticalPadding * paddingMultiplier,
            right: horizontalPadding * paddingMultiplier,
            bottom: Math.round(verticalPadding / 2) * paddingMultiplier,
            left: horizontalPadding * paddingMultiplier,
        },
        size: {
            width: screenWidth,
            height: screenHeight,
        },
    };
};
